import React from "react";
import { Container } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Image = ({ image, ...props }) => {
  if (!image.localFile) {
    return null;
  }

  const img = getImage(image.localFile.childImageSharp);
  return (
    <Container {...props} className="mb-5">
      <GatsbyImage image={img} alt={image.alt || ""} />
    </Container>
  );
};

export default Image;
