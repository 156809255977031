import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Content = styled.div`
  p {
    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }
`;

const TextWithImage = ({ text, image, ...props }) => {
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container className="my-5 py-lg-5">
      <Row className="align-items-center">
        <Col lg={5}>
          <Content>
            <RichText render={text} htmlSerializer={serializer} />
          </Content>
        </Col>
        <Col lg={5} className="ml-auto mt-4 mt-lg-0">
          <GatsbyImage image={img} alt={image.alt || ""} />
        </Col>
      </Row>
    </Container>
  );
};

export default TextWithImage;
