import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";

import Layout from "../components/Layout";
import Image from "../components/project/Image";
import ThreeColText from "../components/project/ThreeColText";
import TextWithImage from "../components/project/TextWithImage";
import ImageColumns from "../components/project/ImageColumns";
import Title from "../components/project/Title";
import Text from "../components/project/Text";
import Button from "../components/Button";
import VideoBlock from "../components/VideoBlock";
import VirtualTour from "../components/VirtualTour";
import Shape from "../images/shape-1.inline.svg";
import Shape2 from "../images/shape-2.inline.svg";

const CenterShape = styled(Parallax)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 1500px;
  width: 180px;
  svg {
    width: 100%;
    height: auto;
  }
`;

const RightShape = styled(Parallax)`
  position: absolute;
  right: 10px;
  top: 1800px;
  width: 180px;
  transform: scaleX(-1);
  svg {
    width: 100%;
    height: auto;
  }
`;

const Project = ({ data }) => {
  const { prismicProyectos } = data;
  const intl = useIntl();

  return (
    <Layout>
      <CenterShape y={[-20, 200]}>
        <Shape />
      </CenterShape>
      <RightShape y={[-20, 200]}>
        <Shape2 />
      </RightShape>
      <h1 className="sr-only">{prismicProyectos?.data.title.text}</h1>
      <Container fluid className="mb-4 mt-5">
        <Row>
          <Col lg={10} className="mx-auto">
            <Button to="/proyectos">
              {intl.formatMessage({ id: "projects_back" })}
            </Button>
          </Col>
        </Row>
      </Container>
      {prismicProyectos?.data.body.map(slice => {
        switch (slice.slice_type) {
          case "image":
            return (
              <Image
                key={slice.id}
                fluid={slice.primary.fluid_container}
                image={slice.primary.image}
              />
            );
          case "three_col_text":
            return (
              <ThreeColText
                key={slice.id}
                fluid={slice.primary.fluid_container}
                columns={slice.items}
              />
            );
          case "text_with_image":
            return (
              <TextWithImage
                key={slice.id}
                text={slice.primary.text.raw}
                image={slice.primary.image}
              />
            );
          case "image_columns":
            return <ImageColumns key={slice.id} columns={slice.items} />;
          case "title":
            return <Title key={slice.id} title={slice.primary.title1.text} />;
          case "text":
            return <Text key={slice.id} text={slice.primary.content.raw} />;
          case "video":
            return (
              <div className="pt-5 mt-5">
                <VideoBlock
                  key={slice.id}
                  fluid={slice.primary.container_fluid}
                  video={slice.primary.video.embed_url}
                />
              </div>
            );
          case "virtual_tour":
            return (
              <div className="pt-5 mt-5">
                <VirtualTour
                  key={slice.id}
                  fluid={slice.primary.container_fluid}
                  url={slice.primary.tour_url.text}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export const query = graphql`
  query($language: String!, $uid: String!) {
    prismicProyectos(uid: { eq: $uid }, lang: { eq: $language }) {
      data {
        title {
          text
        }
        body {
          ... on PrismicProyectosBodyImage {
            id
            slice_type
            primary {
              fluid_container
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicProyectosBodyThreeColText {
            id
            slice_type
            items {
              lighten
              content {
                raw
                text
              }
              alignment
            }
            primary {
              fluid_container
            }
          }
          ... on PrismicProyectosBodyTextWithImage {
            id
            slice_type
            primary {
              text {
                raw
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicProyectosBodyImageColumns {
            id
            slice_type
            items {
              columns
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                    id
                  }
                }
                alt
              }
            }
          }
          ... on PrismicProyectosBodyTitle {
            id
            slice_type
            primary {
              title1 {
                text
              }
            }
          }
          ... on PrismicProyectosBodyText {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicProyectosBodyVideo {
            id
            slice_type
            primary {
              container_fluid
              video {
                embed_url
              }
            }
          }
          ... on PrismicProyectosBodyVirtualTour {
            id
            slice_type
            primary {
              container_fluid
              tour_url {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
