import React from "react";
import { Container } from "react-bootstrap";

const Title = ({ title }) => {
  return (
    <Container className="text-center my-5 pt-lg-5 position-relative">
      <h2>{title}</h2>
    </Container>
  );
};

export default Title;
