import React from "react";
import { Container } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

import { addGifToContent } from "../../utils";

const Text = ({ text }) => {
  const serializer = addGifToContent();
  return (
    <Container className="my-5 pb-lg-5 position-relative">
      <RichText render={text} htmlSerializer={serializer} />
    </Container>
  );
};

export default Text;
