import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const ImageColumns = ({ columns }) => {
  return (
    <Container className="my-5 py-lg-5">
      <Row>
        {columns.map((column, index) => {
          if (!column.image.localFile) {
            return null;
          }

          const img = getImage(column.image.localFile.childImageSharp);
          return (
            <Col
              key={column.image.localFile.childImageSharp?.id || index}
              lg={column.columns}
              className="mb-4 mb-lg-0"
            >
              <GatsbyImage image={img} alt={column.image.alt || ""} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ImageColumns;
