import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import { addGifToContent } from "../../utils";

const Text = styled.div`
  color: ${props => (props.lighten ? props.theme.colors.gray : "#000")};
  strong {
    font-weight: 500;
  }
`;

const ThreeColText = ({ columns, ...props }) => {
  const serializer = addGifToContent();
  return (
    <Container {...props} className="my-5 py-lg-3">
      <Row>
        {columns.map(column => (
          <Col
            key={column.content.text}
            className={`text-${column.alignment.toLowerCase()}`}
          >
            <Text lighten={column.lighten}>
              <RichText
                render={column.content.raw}
                htmlSerializer={serializer}
              />
            </Text>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ThreeColText;
